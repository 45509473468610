import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';
import ReactMarkdown from 'react-markdown';

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  @media screen and ${device.TABLET} {
    font-size: 50px;
  }
  line-height: 1.2;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 1px;
  margin: 16px 0 32px 0;
  @media screen and ${device.TABLET} {
    font-size: 24px;
    margin: 16px 0 57px 0;
  }
`;

export const Wrapper = styled.div`
  ${'' /* max-width: 876px; */}
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 40}px 16px ${styles.layout
  .MOBILE_MENU_HEIGHT + 40}px 16px;
  @media screen and ${device.TABLET} {
    padding: ${styles.layout.MENU_HEIGHT + 100}px 16px 100px 16px;
  }
  display: grid;
  margin: 0 auto;
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
  margin-top: 56px;
  line-height: 1.2;
  letter-spacing: 1px;
`;
export const SectionSubTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 1px;
  @media screen and ${device.TABLET} {
    font-size: 18px;
  }
`;

export const SectionDesc = styled(ReactMarkdown)`
  * {
    line-height: 1.2;
    letter-spacing: 1px;
  }
  p strong {
    margin: 10px 0 8px 0px;
  }
  p {
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
  }
  margin-top: 16px;
  margin-bottom: 10px;
  a {
    text-decoration: underline;
    color: ${styles.colors.DARK_BLUE};
  }
  li {
    list-style-type: disc;
    margin: 10px 0 0 30px;
  }
  h3 {
    margin-top: 16px;
  }
`;

export const BTNTitle = styled.div`
  font-weight: 700;
  font-size: 18px;

  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
  line-height: 1.2;
  letter-spacing: 1px;
`;
export const BTNSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  margin: ${({ isLast }) => (isLast ? '46px 0 0 0' : '46px 0 56px 0')};

  @media screen and ${device.TABLET} {
    ${({ mobileOnly }) => mobileOnly && 'display: none;'}
  }
`;

export const ArticleWrapper = styled.div`
  display: flex;
  gap: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${styles.colors.GRAY_2};
  a {
    text-decoration: none;
    color: inherit;
    display: contents;
  }

  @media screen and ${device.LAPTOP} {
    background: ${styles.colors.NUDE};
    flex-direction: ${({ i }) => (i % 2 === 0 ? 'row-reverse' : 'row')};
    gap: 0;
    height: 336px;
    margin: 0 96px 0 96px;
    padding: 0;
    > * {
      width: 50%;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${styles.colors.DARK_BLUE};
  width: 65%;
  h2 {
    font-size: 18px;
    @media screen and ${device.TABLET} {
      font-size: 32px;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    align-items: center;
    display: flex;
  }
  @media screen and ${device.TABLET} {
    width: 50%;
    padding: 32px;
  }
`;

export const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h2 {
    font-size: 18px;
  }
  p {
    font-size: 24px;
  }
  @media screen and ${device.TABLET} {
    h2 {
      font-size: 32px;
    }
  }
`;

export const MobileHidden = styled.div`
  display: none;
  @media screen and ${device.TABLET} {
    display: block;
  }
`;

export const AverageSection = styled.div`
  margin: 18px 0;
  @media screen and ${device.TABLET} {
    margin: 24px 0 24px 28px;
  }
`;
export const Download = styled.div`
  font-weight: 700;
  font-size: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  color: ${styles.colors.WHITE_NEW};
  background: ${styles.colors.GREEN};
  border-radius: 8px;
  width: 200px;
  height: 48px;
  cursor: pointer;
  font-family: 'HKGrotesk', sans-serif;
  @media screen and ${device.LAPTOP} {
    display: flex;
  }
  &:hover {
    color: ${styles.colors.WHITE_NEW + 'B3'};
    box-shadow: none;
  }
`;

export const MobileDownload = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 35%;
  height: 108px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and ${device.TABLET} {
    width: 50%;
    height: 100%;
  }
`;
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 40}px 16px 100px 16px;
  @media screen and ${device.TABLET} {
    padding: ${styles.layout.MENU_HEIGHT + 100}px 16px 100px 16px;
  }
`;

export const Summary = styled.div`
  margin: 24px 0 70px 0;
`;

export const MainDesc = styled.div`
  margin-bottom: 50px;
`;

export const DownloadImageWrapper = styled.div`
  margin-bottom: 100px;
  screen and ${device.TABLET} {
    margin-bottom: 80px;
  }
`;
