import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

import { createBrowserHistory } from 'history';
import { store, persistor } from 'store';
import { MainNavigation } from 'navigation';
import Modals from 'modals';

const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <Provider store={store}>
          <Router history={history}>
            <PersistGate loading={null} persistor={persistor}>
              <MainNavigation />
              <Modals />
            </PersistGate>
          </Router>
        </Provider>
      </HelmetProvider>
    );
  }
}

export default App;
