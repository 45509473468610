import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  articles: [],
  article: {},
  isLoadingArticle: false,
  isLoadingArticles: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_ARTICLE_FETCH: {
      return {
        ...state,
        isLoadingArticle: true
      };
    }
    case actionTypes.GET_ARTICLES_FETCH: {
      return {
        ...state,
        isLoadingArticles: true
      };
    }
    case actionTypes.GET_ARTICLES_SUCCESS: {
      return {
        ...state,
        isLoadingArticles: false,
        articles: action.payload
      };
    }
    case actionTypes.GET_ARTICLE_SUCCESS: {
      return {
        ...state,
        isLoadingArticle: false,
        article: action.payload
      };
    }
    case actionTypes.GET_ARTICLE_ERROR: {
      return {
        ...state,
        isLoadingArticle: false,
        article: action.payload
      };
    }
    case actionTypes.GET_ARTICLES_ERROR: {
      return {
        ...state,
        isLoadingArticles: false
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectArticles = state => state.article.articles;
export const selectArticle = state => state.article.article;
export const selectLoadingArticles = state => state.article.isLoadingArticles;
export const selectLoadingArticle = state => state.article.isLoadingArticle;
export const selectLoadingPublications = state =>
  state.article.isLoadingArticles;

export default reducer;
