import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getArticles({ payload }) {
  try {
    const response = yield call(Api.cmsGet, '?populate=*&sort=createdAt:desc');

    if (response.data) {
      const rawData = response.data.map(item => ({
        ...item.attributes,
        id: item.id
      }));

      const data = rawData.sort((a, b) => a.publishedAt - b.publishedAt);

      yield put({
        type: actionTypes.GET_ARTICLES_SUCCESS,
        payload: data
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_ARTICLES_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetArticles() {
  yield takeLatest(actionTypes.GET_ARTICLES_FETCH, getArticles);
}
