import styled from 'styled-components/macro';
import { device } from 'common/enums/devices';
import { pageTypes } from 'common/enums/pages';
import styles from 'common/enums/styles';

export const GetAppSection = styled.section`
  padding: 0;
  display: flex;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
  margin-top: ${({ displaySingle }) => (displaySingle ? 0 : 20)}px;
  @media screen and ${device.LAPTOP} {
    display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'flex')};
  }
`;

export const GetAppContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ customButton }) => customButton && 'width: fit-content;'}
`;

export const CTAButton = styled.div`
  background-color: ${styles.colors.PURPLE};
  padding: 10px 18px;
  border-radius: 10px;
  color: #fefefc;
  font-weight: 700;
  display: block;
  text-align: center;
`;

export const SubHeader = styled.h4`
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin: 0 auto 40px;
  line-height: 1.5;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const AppLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isCentered }) =>
    isCentered ? 'center' : 'space-between'};
  flex-wrap: wrap;
  @media screen and (min-width: 450px) {
    flex-direction: row;
  }
`;

export const AppLink = styled.a`
  text-decoration: none;
  width: ${({ customButton }) => (customButton ? 'fit-content' : '190px')};
  height: 56px;

  ${({ isCentered, light, isRight, type }) =>
    (isCentered && !light) || !light
      ? `
      margin-bottom: 8px;
      margin-top: ${isRight && type === pageTypes.REVIEWS ? 9 : 0}px;
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (min-width: 450px) {
        margin: 0 12px 8px;
      }
    `
      : isCentered
      ? `
      margin: 0 12px 20px;
      &:last-child {
        margin-bottom: 0;
      }
    `
      : `
      margin: 0 24px 20px 0;
      &:last-child {
        margin-right: 0;
      }
  `}
`;

export const GetAppArrows = styled.div`
  position: relative;
  align-self: flex-start;
  margin: 0 20px;
  top: 15px;
  ${({ right }) =>
    right &&
    `
      transform: rotateY(180deg);
    `};
  @media screen and ${device.LAPTOP} {
    margin: 0;
    ${({ right }) =>
      right &&
      `
      left: -5px;
    `};
  }
`;
