import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'common/enums/routes';
import { useLocation } from 'react-router-dom';
import { checkRoute } from 'common/helpers';
import { Footer } from 'components';
import { CircleButton, NavBar } from '../';
import DownloadQR from '../DownloadQR/DownloadQR';
import {
  AboutUs,
  AskUsAnything,
  Categories,
  Confirmation,
  DobbyHO,
  FAQ,
  GetStarted,
  // GrowTogetherPage,
  JoinUs,
  Home,
  HomeSF,
  // RoleDescription,
  LoveOurPlanet,
  NotFound,
  MeetDobby,
  // Partners,
  PrivacyPolicy,
  PressRelease,
  Provider,
  Reviews,
  SuccessEmailVerification,
  Unsubscribe,
  Attic,
  Story,
  DobbyCare,
  Subscription,
  PlanningMode,
  Article,
  Articles
} from 'pages';
import { pageTypes } from 'common/enums/pages';

import { openNavigation, closeNavigation } from 'store/actions/navigation';
import { isNavigationOpened } from 'store/reducers/navigation';

import { Content, GlobalStyle } from './styled';

const WithNavBar = ({
  isNavigationOpened,
  openNavigation,
  closeNavigation
}) => {
  const location = useLocation();
  const { isGetStartedRoute, isNavBarShown, isCircleButton } = checkRoute(
    location
  );

  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route path={routes.UNSUBSCRIBE} component={Unsubscribe} />
        <Route
          path={[routes.HANDYMAN_CONFIRMED, routes.SUCCESSFUL_BOOKING]}
          component={Confirmation}
        />
        <Route path="*">
          <>
            {isNavBarShown && (
              <NavBar
                isNavigationOpened={isNavigationOpened}
                openNavigation={openNavigation}
                closeNavigation={closeNavigation}
              />
            )}
            <Content>
              <Switch>
                <Route exact path={routes.HOME} component={Home} />
                <Route exact path={routes.ARTICLE} component={Article} />
                <Route exact path={routes.ARTICLES} component={Articles} />
                <Route exact path={routes.LANDING_BAY} component={HomeSF} />
                <Route exact path={routes.DOBBY_HO} component={DobbyHO} />
                <Route
                  exact
                  path={[routes.REVIEWS, routes.REVIEWS_5_STAR]}
                  component={Reviews}
                />
                <Route
                  exact
                  path={routes.JOIN_US_DMV}
                  component={props => (
                    <JoinUs {...props} page={pageTypes.JOIN_US_DMV} />
                  )}
                />
                <Route
                  exact
                  path={routes.JOIN_US_CHARLOTTE}
                  component={props => (
                    <JoinUs {...props} page={pageTypes.JOIN_US_CHARLOTTE} />
                  )}
                />
                <Route
                  exact
                  path={routes.ASK_US_ANYTHING}
                  component={AskUsAnything}
                />
                <Route
                  exact
                  path={[routes.ATTIC, routes.CHECK_THE_PRICE]}
                  component={Attic}
                />
                <Route
                  exact
                  path={[routes.PLANNING_MODE]}
                  component={PlanningMode}
                />
                <Route
                  exact
                  path={routes.ATTIC_PUBLICATION}
                  component={Story}
                />
                <Route exact path={routes.FAQ} component={FAQ} />
                <Route exact path={routes.DOBBY_CARE} component={DobbyCare} />
                {/* <Route
                  exact
                  path={routes.DOBBY_SUBSCRIPTION}
                  component={Subscription}
                /> */}
                <Route exact path={routes.CATEGORIES} component={Categories} />
                {/* <Route exact path={routes.PARTNERS} component={Partners} /> */}
                <Route exact path={routes.PROVIDER} component={Provider} />
                <Route exact path={routes.ABOUT_US} component={AboutUs} />
                <Route path={routes.MEET_DOBBY} component={MeetDobby} />
                <Route
                  exact
                  path={routes.PRIVACY_POLICY}
                  component={PrivacyPolicy}
                />
                <Route
                  exact
                  path={routes.PRESS_RELEASE}
                  component={PressRelease}
                />
                {/* <Route
                  exact
                  path={routes.GROW_TOGETHER}
                  render={props => (
                    <GrowTogetherPage type="homeowner" {...props} />
                  )}
                /> */}
                <Route
                  exact
                  path={routes.LOVE_OUR_PLANET}
                  component={LoveOurPlanet}
                />
                <Route exact path={routes.GET_STARTED} component={GetStarted} />
                <Route
                  exact
                  path={routes.SUCCESS_EMAIL_VERIFICATION}
                  component={SuccessEmailVerification}
                />
                {/* <Route
                  path={routes.BUILD_TOGETHER}
                  render={props => (
                    <GrowTogetherPage type="provider" {...props} />
                  )}
                /> */}
                {/* <Route
                  path={routes.COMMUNITY}
                  render={props => <Home isCommunityFormShown {...props} />}
                /> */}
                <Route
                  path={routes.REDIRECT_HAPPY_HOMES}
                  render={props => <Home {...props} />}
                />
                <Redirect
                  path={routes.FOUNDERS_LETTER}
                  to={{
                    pathname: routes.ABOUT_US,
                    state: { isRedirected: true }
                  }}
                />
                {/* <Route path={`${routes.ROLE_DESCRIPTION}/:role`} component={RoleDescription} /> */}
                {/*
                    <Route exact path={routes.TERMS_OF_SERVICE} component={TermsOfService} />
                    */}

                <Route path="*" component={NotFound} />
              </Switch>
            </Content>
            {!isGetStartedRoute && <Footer />}
            {/* {isCircleButton && (
              <CircleButton
                bigOffset={false}
                // bigOffset={`/${location.pathname.split('/')[1]}` === routes.ROLE_DESCRIPTION}
              />
            )} */}
            <DownloadQR />
          </>
        </Route>
      </Switch>
    </>
  );
};

WithNavBar.propTypes = {
  isNavigationOpened: PropTypes.bool.isRequired,
  openNavigation: PropTypes.func.isRequired,
  closeNavigation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isNavigationOpened: isNavigationOpened(state)
});

const mapDispatchToProps = {
  openNavigation,
  closeNavigation
};

export default connect(mapStateToProps, mapDispatchToProps)(WithNavBar);
