import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateQueryString } from 'common/helpers/links';

export const useUtmParams = ({ utm_source, page, isDesktop }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const utmParams = useMemo(
    () => ({
      utm_campaign:
        searchParams.get('utm_campaign') || (isDesktop ? 'desktop' : 'mobile'),
      utm_medium: searchParams.get('utm_medium') || page,
      utm_source: searchParams.get('utm_source') || utm_source || 'website'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    utmParams,
    utmParamsString: generateQueryString(utmParams)
  };
};
