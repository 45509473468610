import React from 'react';
import { isDesktop } from 'react-device-detect';

import { Text, GetApp } from 'components';
import { pageTypes } from 'common/enums/pages';
import {
  Wrapper,
  TextWrapper,
  Image,
  Container,
  Title,
  invertedTextStyle,
  GetAppWrapper,
  QRWrapper,
  ContentRow
} from './styled';
import reviewsDownload from 'static/images/rating/reviews-download.png';
import links from 'common/constants/links';
import QRCode from 'react-qr-code';
import styles from 'common/enums/styles';
import { useUtmParams } from 'hooks';
import { getFirebaseDobbyDownloadLink } from 'common/helpers';

const DownloadAppImageText = ({
  entryPoint,
  inverted,
  image,
  heading,
  desc,
  btnTitle,
  articleSource = false
}) => {
  const isAndroid = navigator.userAgent.includes('Android');
  const utmEntry = Boolean(btnTitle) ? `${entryPoint}_${btnTitle}` : entryPoint;
  const { utmParamsString } = useUtmParams({
    page: utmEntry,
    utm_source: 'web_articles',
    isDesktop: isDesktop
  });

  const dobbyAppDownloadLinkIOS = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: window.location.pathname,
    desktopFallbackLink: links.MARKET_HO_APP_IOS
  });

  const dobbyAppDownloadLinkAndroid = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: window.location.pathname,
    desktopFallbackLink: links.MARKET_HO_APP_ANDROID
  });

  return (
    <Wrapper>
      <Container
        inverted={inverted}
        customImage={Boolean(image)}
        articleSource={articleSource}>
        <Image src={image || reviewsDownload} articleSource={articleSource} />
        <TextWrapper>
          <Title text2 textAlign="justify">
            {Boolean(heading)
              ? heading
              : 'The best service pros. \nAll on Dobby.'}
          </Title>
          <ContentRow>
            {btnTitle ? (
              <QRWrapper>
                <QRCode
                  value={
                    isAndroid
                      ? dobbyAppDownloadLinkAndroid
                      : dobbyAppDownloadLinkIOS
                  }
                  title={btnTitle}
                  fgColor={styles.colors.WHITE}
                  bgColor={styles.colors.PURPLE}
                  level="L"
                  size={136}
                />
              </QRWrapper>
            ) : null}
            <Text
              text2
              textAlign="justify"
              style={inverted ? invertedTextStyle : {}}>
              {Boolean(desc)
                ? desc
                : 'Our Pros are incredibly talented, download the Dobby app, and we will connect you with a 5-star Pro!'}
            </Text>
          </ContentRow>
          <GetAppWrapper article>
            <GetApp
              light
              type={pageTypes.REVIEWS}
              mobileOnly
              entryPoint={utmEntry}
              btnTitle={btnTitle}
              articleSource={articleSource}
            />
          </GetAppWrapper>
        </TextWrapper>
      </Container>
    </Wrapper>
  );
};

export default DownloadAppImageText;
