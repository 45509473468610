import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import imgArrowRight from 'static/images/icons/arrow-right-blue.svg';

import {
  Wrapper,
  ArticleWrapper,
  DescWrapper,
  LoaderWrapper,
  Image,
  TextWrapper,
  MobileHidden
} from './styled';

import { analyticsPageTypes } from 'common/enums/pages';
import { getArticles } from 'store/actions/article';
import { selectArticles, selectLoadingArticles } from 'store/reducers/article';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import { useTrackPageViews } from 'hooks';

function extractFirstSentence(text) {
  const firstSentence = text?.match(/[^.!?]+[.!?]/);
  return firstSentence ? firstSentence[0].trim() : '';
}

const Articles = ({ articles, getArticles, isLoading }) => {
  const { page = 1 } = useParams();
  useTrackPageViews({ page: analyticsPageTypes.ARTICLES });

  useEffect(() => {
    getArticles({ page });
    window.scrollTo({ top: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(articles);
  }, [articles]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      {articles?.map((article, index) => (
        <ArticleWrapper key={article?.id} i={index}>
          <Link to={`/articles/${article?.slug}`}>
            <Image>
              <img src={article?.Banner.data.attributes.url} alt="Content" />
            </Image>
            <TextWrapper>
              <DescWrapper>
                <h2>{article?.Title}</h2>
                <MobileHidden>
                  <p>
                    {extractFirstSentence(
                      article?.Body?.replace(/<[^>]*>?/gm, '')
                    )}
                  </p>
                </MobileHidden>
              </DescWrapper>
              <MobileHidden>
                <div>
                  Read more
                  <img src={imgArrowRight} alt="Read more" />
                </div>
              </MobileHidden>
            </TextWrapper>
          </Link>
        </ArticleWrapper>
      ))}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: selectLoadingArticles(state),
  articles: selectArticles(state)
});

const mapDispatchToProps = {
  getArticles
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
