import React from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { connect } from 'react-redux';

import { useUtmParams } from 'hooks';
import links from 'common/constants/links';
import { analyticsPageTypes } from 'common/enums/pages';
import { getFirebaseDobbyDownloadLink } from 'common/helpers/links';
import { GooglePlayIcon, AppStoreIcon } from 'components';
import { selectUserId } from 'store/reducers/user';
import {
  GetAppSection,
  GetAppContentWrapper,
  GetAppArrows,
  SubHeader,
  Image,
  AppLink,
  AppLinks,
  CTAButton
} from './styled';
import styles from 'common/enums/styles';
import { isMobile } from 'react-device-detect';
import arrow from 'static/images/icons/grow-together-arrow.svg';
import { useLocation } from 'react-router-dom';

const GetApp = ({
  userId,
  entryPoint = analyticsPageTypes.HOME,
  eventNameEntryPoint,
  appName = 'HO',
  hasArrows,
  displaySingle,
  hasTitle,
  hasAllLinks = false,
  useDirectLinks = false,
  light,
  isCentered,
  mobileOnly,
  type,
  modal = false,
  btnTitle,
  articleSource = false
}) => {
  const location = useLocation();
  const utmBtnTitle = btnTitle
    ? btnTitle.replace(/\s+/g, '_').toLowerCase()
    : '';
  const { utmParamsString } = useUtmParams(
    articleSource
      ? { page: entryPoint, utm_source: 'web_articles' }
      : { page: entryPoint }
  );
  const isAndroid = navigator.userAgent.includes('Android');
  let dobbyAppDownloadLinkAndroid = links.MARKET_HO_APP_ANDROID;
  let dobbyAppDownloadLinkIOS = links.MARKET_HO_APP_IOS;
  if (!useDirectLinks) {
    dobbyAppDownloadLinkIOS = getFirebaseDobbyDownloadLink({
      utmParamsString,
      pathname: location.pathname,
      desktopFallbackLink: links.MARKET_HO_APP_IOS
    });
    dobbyAppDownloadLinkAndroid = getFirebaseDobbyDownloadLink({
      utmParamsString,
      pathname: location.pathname,
      desktopFallbackLink: links.MARKET_HO_APP_ANDROID
    });
  }

  const backgroundColor = modal
    ? styles.colors.PURPLE
    : light
    ? styles.colors.BLUE
    : styles.colors.WHITE_NEW;
  const color = modal
    ? styles.colors.PURE_WHITE
    : light
    ? styles.colors.WHITE_NEW
    : styles.colors.BLUE;
  const highlightColor = modal
    ? styles.colors.PURE_WHITE
    : styles.colors.PURE_BLACK;
  const desktopLink = isMobile ? {} : { target: '_blank' };

  const displayIOS = !isAndroid || hasAllLinks;
  const displayAndroid = isAndroid || hasAllLinks;

  return (
    <GetAppSection
      mobileOnly={mobileOnly}
      isCentered={isCentered}
      displaySingle={displaySingle}>
      {hasArrows && (
        <GetAppArrows>
          <Image src={arrow} alt="arrow-left" />
        </GetAppArrows>
      )}
      <GetAppContentWrapper customButton={Boolean(btnTitle)}>
        {hasTitle && (
          <SubHeader>Download Dobby {appName === 'SP' ? 'Pro' : ''}</SubHeader>
        )}
        <AppLinks isCentered={isCentered}>
          {displayIOS && (
            <AppLink
              {...desktopLink}
              isCentered={isCentered}
              light={light}
              href={
                appName === 'HO'
                  ? dobbyAppDownloadLinkIOS
                  : links.MARKET_SP_APP_IOS
              }
              rel="noopener noreferrer"
              onClick={() =>
                mixpanel.track(
                  `${eventNameEntryPoint ||
                    entryPoint} ${appName} download iOS`,
                  {
                    'Entry Point': eventNameEntryPoint || entryPoint,
                    $user_id: userId
                  }
                )
              }
              customButton={Boolean(btnTitle)}>
              {btnTitle ? (
                <CTAButton>{btnTitle}</CTAButton>
              ) : (
                <AppStoreIcon
                  alt="Download from App Store"
                  highlightColor={highlightColor}
                  backgroundColor={backgroundColor}
                  color={color}
                  modal={modal}
                />
              )}
            </AppLink>
          )}
          {displayAndroid && (
            <AppLink
              {...desktopLink}
              isCentered={isCentered}
              light={light}
              isRight
              href={
                appName === 'HO'
                  ? dobbyAppDownloadLinkAndroid
                  : links.MARKET_SP_APP_ANDROID
              }
              type={type}
              rel="noopener noreferrer"
              onClick={() =>
                mixpanel.track(
                  `${eventNameEntryPoint ||
                    entryPoint} ${appName} download Android`,
                  {
                    'Entry Point': eventNameEntryPoint || entryPoint,
                    $user_id: userId
                  }
                )
              }>
              {btnTitle ? (
                <CTAButton>{btnTitle}</CTAButton>
              ) : (
                <GooglePlayIcon
                  alt="Get it on Google Play"
                  highlightColor={highlightColor}
                  backgroundColor={backgroundColor}
                  color={color}
                  modal={modal}
                />
              )}
            </AppLink>
          )}
        </AppLinks>
      </GetAppContentWrapper>
      {hasArrows && (
        <GetAppArrows right>
          <Image src={arrow} alt="arrow-right" />
        </GetAppArrows>
      )}
    </GetAppSection>
  );
};

GetApp.propTypes = {
  userId: PropTypes.number
};

const mapStateToProps = state => ({
  userId: selectUserId(state)
});

export default connect(mapStateToProps)(GetApp);
